import axios from 'axios';

const API_URL = 'https://notforeveryone.ua/api2.php';
const DEBUG = '0';

export const fetchBrands = () => {
  return axios.get(`${API_URL}/brands`);
};

export const fetchCategories = () => {
  return axios.get(`${API_URL}/categories`);
};

export const fetchProducts = (url) => {
  return axios.get(`${API_URL}/products?${url}`);
};

//### PRODUCT PAGE

export const fetchProduct = (id) => {
  return axios.get(`${API_URL}/products/${id}`);
};

export const fetchSubmitOrder = (
  prodId,
  phone,
  name,
  comment,
  city,
  warehouse,
  paymentOption
) => {
  return axios.get(
    `${API_URL}/order?prodId=${prodId}&phone=${phone}&name=${name}&comment=${comment}&city=${city}&warehouse=${warehouse}&payment=${paymentOption}&debug=${DEBUG}`
  );
};

export const fetchSubmitConsultation = (prodId, phone, name, comment) => {
  return axios.get(
    `${API_URL}/consultation?prodId=${prodId}&phone=${phone}&name=${name}&comment=${comment}&debug=${DEBUG}`
  );
};

// END PRODUCT PAGE ###
